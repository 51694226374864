import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-primary text-white p-5">
      <div className="container mx-auto max-w-screen-xl flex justify-between">
        <nav>
          <ul className="flex space-x-5">
            <li><Link to="/impressum" className="text-white text-lg">Impressum</Link></li>
            <li><Link to="/datenschutz" className="text-white text-lg">Datenschutz</Link></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;