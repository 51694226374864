import React from 'react';

const Impressum: React.FC = () => {
    return (
        <section className="p-10">
            <h1 className="text-center text-5xl font-bold mb-10">Impressum</h1>
            <div className="container mx-auto max-w-screen-md bg-white p-10">
                <p className="text-lg mb-4">
                    <strong>MS Cloud Consulting Dohm & Günther GbR</strong>
                </p>
                <p className="text-lg mb-4">
                    vertreten durch die Gesellschafter Mattias Dohm und Sascha Günther
                </p>
                <p className="text-lg mb-4">
                    Grindelhof 19<br />
                    D – 20146 Hamburg
                </p>
                <p className="text-lg mb-4">
                    E-Mail: <a href="mailto:info@mscloud.consulting" className="text-primary underline">info@mscloud.consulting</a>
                </p>
                <p className="text-lg">
                    USt-Identifikationsnummer: DE343389888
                </p>
            </div>
        </section>
    );
};

export default Impressum;