import React, { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';

interface HeaderProps {
  scrolled: boolean;
}

const Header: React.FC<HeaderProps> = ({ scrolled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={`fixed w-full top-0 z-50 p-5 transition-colors duration-300 bg-white text-primary ${scrolled ? "shadow-lg" : ""}`}>
      <div className="container mx-auto max-w-screen-xl flex justify-between items-center">
        <a href="/">
          <img src="/assets/mscc-logo.svg" alt="Logo" className="h-10" />
        </a>
        <nav className="hidden md:flex space-x-5">
          <ul className="flex space-x-5">
            <li><a href="/#services" className="text-lg text-primary uppercase">Leistungen</a></li>
            <li><a href="/#about" className="text-lg text-primary uppercase">Über uns</a></li>
            <li><a href="/#contact" className="text-lg text-primary uppercase">Kontakt</a></li>
          </ul>
        </nav>
        <div className="md:hidden">
          <button onClick={toggleMenu} className={`focus:outline-none ${scrolled ? 'text-white' : 'text-primary'}`}>
            {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className="md:hidden">
          <ul className="flex flex-col space-y-5 mt-5">
            <li><a href="/#services" className="text-primary text-lg">Leistungen</a></li>
            <li><a href="/#about" className="text-primary text-lg">Über uns</a></li>
            <li><a href="/#contact" className="text-primary text-lg">Kontakt</a></li>
          </ul>
        </nav>
      )}
    </header>
  );
}

export default Header;