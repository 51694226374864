import React from 'react';

const Datenschutz: React.FC = () => {
    return (
        <section className="p-10">
            <h1 className="text-center text-5xl font-bold mb-10">Datenschutzerklärung</h1>
            <div className="container mx-auto max-w-screen-md bg-white p-10">
                <p className="text-lg mb-4">
                    <strong>MS Cloud Consulting Dohm & Günther GbR</strong>
                </p>
                <p className="text-lg mb-4">
                    vertreten durch die Gesellschafter Mattias Dohm und Sascha Günther
                </p>
                <p className="text-lg mb-4">
                    Grindelhof 19<br />
                    D – 20146 Hamburg
                </p>
                <p className="text-lg mb-4">
                    E-Mail: <a href="mailto:info@mscloud.consulting" className="text-primary underline">info@mscloud.consulting</a>
                </p>
                <p className="text-lg mb-4">
                    USt-Identifikationsnummer: DE343389888
                </p>
                <h2 className="text-2xl font-bold mt-10 mb-4">Allgemeiner Hinweis und Pflichtinformationen</h2>
                <h3 className="text-xl font-bold mb-2">Benennung der verantwortlichen Stelle</h3>
                <p className="text-lg mb-4">
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                </p>
                <p className="text-lg mb-4">
                    MS Cloud Consulting Dohm & Günther GbR<br />
                    Mattias Dohm & Sascha Günther<br />
                    Grindelhof 19<br />
                    20146 Hamburg
                </p>
                <p className="text-lg mb-4">
                    Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
                </p>
                <h3 className="text-xl font-bold mb-2">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                <p className="text-lg mb-4">
                    Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </p>
                <h3 className="text-xl font-bold mb-2">Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h3>
                <p className="text-lg mb-4">
                    Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" className="text-primary underline" target="_blank" rel="noopener noreferrer">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
                </p>
                <h3 className="text-xl font-bold mb-2">Recht auf Datenübertragbarkeit</h3>
                <p className="text-lg mb-4">
                    Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                </p>
                <h3 className="text-xl font-bold mb-2">Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h3>
                <p className="text-lg mb-4">
                    Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
                </p>
                <h3 className="text-xl font-bold mb-2">SSL- bzw. TLS-Verschlüsselung</h3>
                <p className="text-lg mb-4">
                    Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
                </p>
                <h3 className="text-xl font-bold mb-2">Kontaktformular</h3>
                <p className="text-lg mb-4">
                    Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.
                </p>
                <p className="text-lg mb-4">
                    Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                </p>
                <p className="text-lg mb-4">
                    Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
                </p>
                <h3 className="text-xl font-bold mb-2">Cookies</h3>
                <p className="text-lg mb-4">
                    Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
                </p>
                <p className="text-lg mb-4">
                    Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen.
                </p>
                <p className="text-lg mb-4">
                    Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.
                </p>
                <p className="text-lg mb-4">
                    Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser Datenschutzerklärung separat behandelt.
                </p>
                <h3 className="text-xl font-bold mb-2">Google Web Fonts</h3>
                <p className="text-lg mb-4">
                    Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
                </p>
                <p className="text-lg mb-4">
                    Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem Server von Google in den USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung und Übertragung unseres Webauftritts.
                </p>
                <p className="text-lg mb-4">
                    Das Unternehmen Google ist für das us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert. Dieses Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten.
                </p>
                <p className="text-lg mb-4">
                    Einzelheiten über Google Web Fonts finden Sie unter: <a href="https://www.google.com/fonts#AboutPlace:about" className="text-primary underline" target="_blank" rel="noopener noreferrer">https://www.google.com/fonts#AboutPlace:about</a> und weitere Informationen in den Datenschutzbestimmungen von Google: <a href="https://policies.google.com/privacy/partners?hl=de" className="text-primary underline" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy/partners?hl=de</a>
                </p>
                <p className="text-lg mb-4">
                    Quelle: Datenschutz-Konfigurator von mein-datenschutzbeauftragter.de
                </p>
            </div>
        </section>
    );
};

export default Datenschutz;