import React from 'react';
import { CloudIcon, CogIcon, AcademicCapIcon, CodeBracketIcon, WrenchScrewdriverIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/solid';

const services = [
  {
    title: "MICROSOFT 365 / AZURE",
    description:
      "Wir bieten Beratung für die gesamte Microsoft 365 & Azure Dienste. Unser Ziel ist es, dass Sie das gesamte Potential der Plattform ausschöpfen, damit Sie Ihre IT modern, sicher und effektiv nutzen.",
    icon: <CloudIcon className="h-10 w-10 text-primary mx-auto" />,
  },
  {
    title: "CLOUD MIGRATIONEN",
    description:
      "Ihre aktuellen Daten liegen in einer Cloud oder auf Servern in Ihrem Haus? Wir migrieren die Daten in ein einheitliches Ecosystem, angefangen von Mailserver bis hin zur komplexen Datenmigration inklusive Neustrukturierung der Arbeitsbereiche.",
    icon: <ArrowsPointingOutIcon className="h-10 w-10 text-primary mx-auto" />,
  },
  {
    title: "PROZESSAUTOMATISIERUNG",
    description:
      "Papier- und Mailchaos war gestern: Wir digitalisieren und automatisieren Geschäftsprozesse. Hierbei spielt nicht selten die Integration mehrerer Plattformen eine wichtige Rolle – dank der Microsoft Power Plattform kein Problem und schnell umsetzbar!",
    icon: <CogIcon className="h-10 w-10 text-primary mx-auto" />,
  },
  {
    title: "INDIVIDUALENTWICKLUNG",
    description:
      "Sie haben individuelle Anforderungen, welche mit Standardmitteln nicht mehr abdeckbar sind? Wir erarbeiten gemeinsam ein Konzept und entwickeln eine maßgeschneiderte Software auf Basis der Microsoft Cloud Umgebung.",
    icon: <CodeBracketIcon className="h-10 w-10 text-primary mx-auto" />,
  },
  {
    title: "SCHULUNG",
    description:
      "Aufgrund der Komplexität und der kontinuierlichen Weiterentwicklung von Microsoft 365 ist es schwierig, auf dem Laufenden zu bleiben. Wir schulen Ihre Mitarbeiter hinsichtlich Bedienung und Funktionalität, sowohl für Standard- als auch Individuallösungen.",
    icon: <AcademicCapIcon className="h-10 w-10 text-primary mx-auto" />,
  },
  {
    title: "SUPPORT",
    description:
      "Nach Projektabschluss machen wir uns nicht aus dem Staub! Bei Anpassungswünschen oder Ad-Hoc Support stehen wir Ihnen zur Verfügung!",
    icon: <WrenchScrewdriverIcon className="h-10 w-10 text-primary mx-auto" />,
  },
];

const Services: React.FC = () => {
  return (
    <section id="services" className="w-full">
      <h1 className="text-center text-5xl font-bold mb-10">Unsere Leistungen</h1>
      <div className="container mx-auto max-w-content">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white text-center p-5 rounded-lg shadow-md transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
            >
              <div className="mb-3">{service.icon}</div>
              <h3 className="text-xl font-bold">{service.title}</h3>
              <p className="mt-2">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;