import React, { useEffect, useMemo, useState } from 'react';
import Slider from "react-slick";
import { useSpring, animated } from '@react-spring/web';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { Container, ISourceOptions, MoveDirection, OutMode } from '@tsparticles/engine';
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.

const Introduction: React.FC = () => {
    const props = useSpring({
        from: { opacity: 0, transform: 'translateY(-50px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { duration: 1000 },
    });

    const [init, setInit] = useState(false);

    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            //await loadFull(engine);
            await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);


    const particlesLoaded = async (container?: Container): Promise<void> => {
        console.log(container);
    };

    const options: ISourceOptions = useMemo(
        () => ({
            background: {
                color: {
                    value: "#ffffff",
                },
            },
            fullScreen: false,
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#002C50",
                },
                links: {
                    color: "#002C50",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                },
                move: {
                    direction: MoveDirection.none,
                    enable: true,
                    outModes: {
                        default: OutMode.out,
                    },
                    random: false,
                    speed: 2,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                    },
                    value: 60,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 5 },
                },
            },
            detectRetina: true,
        }),
        [],
    );

    const settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="relative bg-center py-20 overflow-hidden">
            {/* {init && <Particles className="absolute inset-0" id="tsparticles" options={options} particlesLoaded={particlesLoaded} />} */}
            <div className="relative container mx-auto max-w-screen-xl text-center text-primary">
                <animated.div style={props}>
                    <h1 className="text-5xl font-bold mb-5">Willkommen bei MS Cloud Consulting</h1>
                    <p className="text-lg mb-10">
                        Wir sind Dienstleister für moderne Zusammenarbeit basierend auf Microsoft 365 und stehen Ihnen bei Beratung, Implementierung und Wartung zur Seite. Gemeinsam erstellen wir passgenaue Lösungen, um Ihren Arbeitsalltag in der digitalen Welt so einfach wie möglich zu gestalten.
                    </p>
                    <div className="flex flex-col md:flex-row justify-center space-y-5 md:space-y-0 md:space-x-5 mb-10">
                        <div className="bg-primary text-white p-5 rounded-lg shadow-md">
                            <h2 className="text-2xl font-bold uppercase">Expertise</h2>
                            <p className="mt-2">Wir verfügen über jahrelange Erfahrung in der Cloud-Beratung.</p>
                        </div>
                        <div className="bg-primary text-white p-5 rounded-lg shadow-md">
                            <h2 className="text-2xl font-bold uppercase">Innovation</h2>
                            <p className="mt-2">Wir bringen innovative Lösungen für Ihre geschäftlichen Herausforderungen.</p>
                        </div>
                        <div className="bg-primary text-white p-5 rounded-lg shadow-md">
                            <h2 className="text-2xl font-bold uppercase">Support</h2>
                            <p className="mt-2">Wir bieten kontinuierlichen Support, um Ihren Erfolg zu gewährleisten.</p>
                        </div>
                    </div>
                </animated.div>
                <div className="py-5">
                    <h2 className="text-lg text-primary mb-10">Eine kleine Auswahl an Unternehmen, die uns bereits vertrauen</h2>
                    <Slider {...settings}>
                        <animated.div style={props}>
                            <a href="https://www.acrolinx.com" target="_blank" rel="noreferrer">
                                <img src="/assets/acrolinx-logo.png" alt="Acrolinx GmbH" className="h-10 mx-auto" />
                            </a>
                        </animated.div>
                        <animated.div style={props}>
                            <a href="https://www.cgrd.de" target="_blank" rel="noreferrer">
                                <img src="/assets/cgrd-logo.png" alt="cgrd Gmbh" className="h-10 mx-auto" />
                            </a>
                        </animated.div>
                        <animated.div style={props}>
                            <a href="https://www.e-b-ing.de/" target="_blank" rel="noreferrer">
                                <img src="/assets/eb-logo.png" alt="EB ingenieur GmbH" className="h-10 mx-auto" />
                            </a>
                        </animated.div>
                        <animated.div style={props}>
                            <a href="https://www.pinktum.com/de/" target="_blank" rel="noreferrer">
                                <img src="/assets/pinktum-logo.svg" alt="Pinktum" className="h-10 mx-auto" />
                            </a>
                        </animated.div>
                        <animated.div style={props}>
                            <a href="https://www.sterlink.de/" target="_blank" rel="noreferrer">
                                <img src="/assets/sterlink-logo.png" alt="Sterlink GmbH" className="h-10 mx-auto" />
                            </a>
                        </animated.div>
                        {/* <animated.div style={props}>
                            <a href="https://www.arkil.de/" target="_blank" rel="noreferrer">
                                <img src="/assets/arkil-logo.png" alt="Arkil Holding GmbH" className="h-10 mx-auto" />
                            </a>
                        </animated.div> */}
                        <animated.div style={props}>
                            <a href="https://www.pawlik-group.com/" target="_blank" rel="noreferrer">
                                <img src="/assets/pawlik-logo.png" alt="Pawlik Consultants GmbH" className="h-10 mx-auto" />
                            </a>
                        </animated.div>

                        {/* <animated.div style={props}>
                            <img src="/assets/sbb-logo.svg" alt="Stiftung Berufliche Bildung" className="h-10 mx-auto" />
                        </animated.div>  */}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default Introduction;