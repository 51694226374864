import React from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/solid';

const About: React.FC = () => {
  return (
    <section id="about" className="p-10">
      <h1 className="text-center text-5xl font-bold mb-10">Das sind wir</h1>
      <div className="flex justify-center">
        <div className="w-full max-w-screen-xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 justify-center">
            <div className="bg-primary text-white p-5 rounded-lg shadow-md mx-auto text-center">
              <img src="/assets/profile-mdohm.jpg" alt="Mattias Dohm" className="w-32 h-32 object-cover rounded-full mx-auto mb-4 border-4 border-white" />
              <div className="p-5">
                <h3 className="text-xl font-bold">MATTIAS DOHM</h3>
                <h4 className="text-md text-gray-300">TECHNICAL CLOUD CONSULTANT</h4>
                <p className="mt-2">
                  Mattias ist seit 2011 in der IT tätig und seit 2015 spezialisiert im Microsoft Cloud Bereich. Seine Tätigkeitsschwerpunkte beziehen sich auf Exchange Online, Cloud Migration, Microsoft 365 und Azure.
                </p>
                <p className="mt-2">
                  +49 (0) 151 283 624 22
                  <br />
                  <a href="mailto:mdohm@mscloud.consulting" className="text-white underline">mdohm@mscloud.consulting</a>
                </p>
                <div className="flex justify-center space-x-3 mt-3">
                  <a href="https://www.linkedin.com/in/mattias-dohm-190811b4/" target="_blank" rel="noopener noreferrer">
                    <img src="/assets/linkedin-icon.png" alt="LinkedIn" className="h-6 w-6" />
                  </a>
                  <a href="mailto:mdohm@mscloud.consulting" target="_blank" rel="noopener noreferrer">
                    <EnvelopeIcon className="h-6 w-6 text-white" />
                  </a>
                </div>
              </div>
            </div>
            <div className="bg-primary text-white p-5 rounded-lg shadow-md mx-auto text-center">
              <img src="/assets/profile-sguenther.jpg" alt="Sascha Günther" className="w-32 h-32 object-cover rounded-full mx-auto mb-4 border-4 border-white" />
              <div className="p-5">
                <h3 className="text-xl font-bold">SASCHA GÜNTHER</h3>
                <h4 className="text-md text-gray-300">CLOUD CONSULTANT / DEVELOPER</h4>
                <p className="mt-2">
                  Sascha ist seit 2011 im Microsoft SharePoint Bereich tätig und seit 2015 spezialisiert im Bereich Microsoft 365, Azure und SharePoint. Sein Schwerpunkt liegt in der Prozessdigitalisierung, individuellen Softwarelösungen und Automatisierung.
                </p>
                <p className="mt-2">
                  +49 (0) 152 243 310 73
                  <br />
                  <a href="mailto:sguenther@mscloud.consulting" className="text-white underline">sguenther@mscloud.consulting</a>
                </p>
                <div className="flex justify-center space-x-3 mt-3">
                  <a href="https://www.linkedin.com/in/saschagnthr/" target="_blank" rel="noopener noreferrer">
                    <img src="/assets/linkedin-icon.png" alt="LinkedIn" className="h-6 w-6" />
                  </a>
                  <a href="mailto:sguenther@mscloud.consulting" target="_blank" rel="noopener noreferrer">
                    <EnvelopeIcon className="h-6 w-6 text-white" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;