import React, { useState } from 'react';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here
    fetch('https://prod-15.westeurope.logic.azure.com:443/workflows/aa6460cc252b44799890bd8aed2b5ef7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Brbj2tsJlDtyALOxwtVJ3ENV4G7ul_z4uyCye7XfBHE', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Name: formData.name,
        Company: formData.company,
        Phone: formData.phone,
        Mail: formData.email,
        Message: formData.message,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        setFormData({
          name: '',
          company: '',
          phone: '',
          email: '',
          message: '',
        });
        setSuccessMessage('Ihre Nachricht wurde erfolgreich gesendet!');
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <section id="contact" className="p-10">
      <h1 className="text-center text-5xl font-bold mb-10">Kontakt</h1>
      <div className="text-center text-lg mb-10">
        <p className="mb-5">
          Sie haben Fragen rund um das Thema Microsoft Cloud? Sprechen Sie uns einfach direkt an, nutzen Sie das untenstehende Kontaktformular oder schicken uns eine Mail an <a href="mailto:info@mscloud.consulting" className="text-primary font-bold">info@mscloud.consulting</a>!
        </p>
        <p>
          Ob ein unverbindliches Erstgespräch, persönliches Kennenlernen oder Expertenaustausch – wir freuen uns über Ihre Kontaktanfrage und nehmen uns gerne die Zeit für Sie!
        </p>
      </div>
      <div className="container mx-auto max-w-screen-md bg-white p-10 rounded-lg shadow-md">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-lg font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="company" className="block text-lg font-medium text-gray-700">Firma</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-lg font-medium text-gray-700">Telefon</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-lg font-medium text-gray-700">E-Mail</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-lg font-medium text-gray-700">Nachricht</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              rows={4}
            />
          </div>
          <div className="text-center">
            <button type="submit" className="bg-primary text-white px-4 py-2 rounded-md">Absenden</button>
          </div>
          {successMessage && (
            <div className="mt-4 text-green-500">
              {successMessage}
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

export default Contact;