import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Header from './components/Header';
import Introduction from './components/Introduction';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';

function App() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Header scrolled={scrolled} />
      <main className="pt-20">
        <Routes>
          <Route path="/" element={
            <>
              <Introduction />
              <section className="bg-primary bg-opacity-10 w-full">
                <div className="container py-10">
                  <Services />
                </div>
              </section>
              <section className="w-full">
                <div className="container py-10">
                  <About />
                </div>
              </section>
              <section className="bg-primary bg-opacity-10 w-full">
                <div className="container py-10">
                  <Contact />
                </div>
              </section>
            </>
          } />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
        </Routes>
      </main>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        cookieName="mscc-cookie-consent"
        style={{ background: "#002C50" }}
        buttonStyle={{ background: "#fff", color: "#002C50", fontSize: "13px" }}
        expires={150}
      >
        Wir verwenden Cookies, um unsere Website und unseren Service zu optimieren.{" "}
        <a href="/datenschutz" style={{ color: "#fff", textDecoration: "underline" }}>Mehr erfahren</a>
      </CookieConsent>
    </div>
  );
}

export default App;